@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.paragraphs-item-hp-who-does-it {
  background: #222222;
  @include clearfix;

  padding: 30px 0 26px;
  @include breakpoint($desktop) {
    padding: 60px 0 60px;
  }

  .quotes {
    min-height: 100px;
  }

  .side-heading {
    color: white;
  }
  .ds-body-top {
    p {
      color: white;
      margin: 0;
      font-size: 19px;
    }
    margin-bottom: 30px;
  }

  .ds-body-bottom {
    position: relative;
  }

  //h3 {
  //  color: $color-gold;
  //  font-family: $font-headings;
  //  font-size: 30px;
  //  text-transform: uppercase;
  //}

  blockquote {
    background: transparent;
    padding: 50px 0 0 0;
    position: relative;
    margin: 10px 0 0;

    p {
      border-left: 6px solid $color-gold;
      padding-left: 20px;
    }

    &:before {
      @include breakpoint($desktop) {
        font-size: 150px;
        left: 20px;
      }
    }
    &:after {
      @include breakpoint($desktop) {
        //line-height: 1em;
        color: #717171;
        position: absolute;

        content: "\201D";
        font-size: 150px;
        right: 0;
        bottom: -50px;
        line-height: 0;
      }
    }
  }

  .field-name-field-quote {
    display: none;
  }
}

.Quotes .paragraphs-item-hp-bit-person {
  opacity: 0.3;
}

.Quotes .selected .paragraphs-item-hp-bit-person {
  opacity: 1;
}

.paragraphs-item-hp-bit-person {
  .field-name-field-body {
    background: $color-gold;
    padding: 10px 20px 14px;
    p {
      color: white;
      margin: 0;
      font-size: 17px;
      font-weight: bold;
      line-height: 1.2em;
    }
    p:last-child {
      text-transform: uppercase;
    }
    p:first-child {
      font-size: 20px;
    }
  }
}
